<template>
  <router-view v-if="isReady && clientVerified"></router-view>
</template>

<script lang="js">
import { isProcessStateOK } from '@/base/js/ProcessDataHelper';

export default {
  name: 'calcRepAdmin',
  data () {
    return {
      clientVerified: false
    };
  },
  computed: {
    isReady () {
      if (this.$store.state.base.admin.administratedOrg != null &&
          this.$i18n.keyExists('calcRepAdmin.packageId')) {
        return true;
      }
      return false;
    }
  },
  mounted () {
    // nur Aufruf bei Seitenwechsel
    if (this.$store.state.calcRepAdmin.clientKey == null) {
      // this.$store.state.base.org.uid !== this.$store.state.calcRepAdmin.administratableClientKey) {
      this.$logger.debug('calcRepAdminVue', 'mounted: ', 'if');
      this.initClient();
    } else { // wenn keine Änderung des "aktuell administrierten Kontos erfolgt ist
      if (this.$store.state.calcRepAdmin.administratableClientKey !== this.$store.state.base.admin.administratedOrg.uid) {
        this.refreshReportList();
      } else {
        this.$logger.debug('calcRepAdminVue', 'mounted: ', 'else');
        this.clientVerified = true;
      }
    }
  },
  watch: {
    // wenn auf der aktuellen Seite eine Änderung des administrierten Kontos erfolgt
    '$store.state.base.admin.administratedOrg': function () {
      this.refreshReportList();
    }
  },
  methods: {
    initClient () {
      // this.$store.state.base.org.uid = orgkey des angemeldeten User
      this.$store.state.calcRepAdmin.clientKey = this.$store.state.base.org.uid;
      // this.$store.state.base.admin.administratedOrg.uid = orgkey des derzeit ausgewählten administrativen Konto
      this.$store.state.calcRepAdmin.administratableClientKey = this.$store.state.base.admin.administratedOrg.uid;

      this.clientVerified = false;

      let ioData = {
        clientKey: this.$store.state.base.org.uid,
        childs: [],
        clientName: this.$store.state.base.org.name,
        dateFrom: '',
        dateTo: ''
      };
      let currentKey = '';
      if (typeof this.$store.state.base.admin.administratedOrg.treeParams === 'undefined') {
        currentKey = this.$store.state.base.admin.administratedOrg.shortname + '_';
      } else {
        currentKey = this.$store.state.base.admin.administratedOrg.treeParams.treestring;
      }
      for (let adminOrg of this.$store.state.base.admin.administratableOrgs) {
        if (adminOrg.treeParams.treestring.includes(currentKey)) {
          if (adminOrg.uid !== this.$store.state.base.admin.administratedOrg.uid) {
            let child = {};
            child.clientKey = adminOrg.uid;
            child.clientName = adminOrg.name;
            ioData.childs.push(child);
          }
        }
      }
      this.$store.state.calcRepAdmin.reportsLoading = true;
      this.$restClient.callProcess('calcRepAdmin', 'getReportingList', ioData).then(
        (processData) => {
          if (isProcessStateOK(processData)) {
            this.$store.state.calcRepAdmin.reportList = processData.ioData.result;
            this.$store.state.calcRepAdmin.currentReportList = processData.ioData.result;
            this.clientVerified = true;
          }
          this.$globals.Info.open(processData.processState.messages);
        }
      );
      this.clientVerified = true;
      this.$store.state.calcRepAdmin.reportsLoading = false;
    },
    refreshReportList () {
      this.$logger.debug('calcRepAdminVue', 'this.$store.state.calcRepAdmin.administratableClientKey: ', this.$store.state.calcRepAdmin.administratableClientKey);
      this.$logger.debug('calcRepAdminVue', 'this.$store.state.base.admin.administratedOrg.uid: ', this.$store.state.base.admin.administratedOrg.uid);
      this.$store.state.calcRepAdmin.reportsLoading = true;
      if (this.$store.state.calcRepAdmin.administratableClientKey !== this.$store.state.base.admin.administratedOrg.uid) {
        this.$store.state.calcRepAdmin.currentReportList = [];
        for (let report of this.$store.state.calcRepAdmin.reportList) {
          for (let adminOrg of this.$store.state.base.admin.administratableOrgs) {
            if (adminOrg.treeParams.treestring.includes(this.$store.state.base.admin.administratedOrg.treeParams.treestring)) {
              if (report.clientKey === adminOrg.uid) {
                this.$store.state.calcRepAdmin.currentReportList.push(report);
              }
            }
          }
        }
        this.$store.state.calcRepAdmin.administratableClientKey = this.$store.state.base.admin.administratedOrg.uid;
      }
      this.$store.state.calcRepAdmin.reportsLoading = false;
    }
  }
};
</script>
