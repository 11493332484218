<template>

  <v-card
    class="minContent"
    v-if="reportList != null">
    <v-card-title>
      <span class="ml-8 body-1 secondary--text">
        {{ reportList.length + " " + $t('calcRepAdmin.calcRepCountTable.countedValues') }}
      </span>
      <v-spacer />
      <v-icon
        color="secondary"
        class="mr-8"
        v-tooltip="$tooltip($t('calcRepAdmin.tooltip.refresh'))"
      >mdi-reload</v-icon>

      <v-menu
        offset-y
        :close-on-content-click="false"
      >

        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            color="secondary"
            v-tooltip="$tooltip($t('calcRepAdmin.tooltip.menu'))"
          >mdi-format-columns</v-icon>
        </template>

        <v-list>
          <v-list-item
            v-for="(col, index) in availableColumns"
            :key="index"
          >
            <v-checkbox
              v-model="availableColumns[index].active"
              :label="$t(availableColumns[index].text)"
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-icon
        @click="saveUserSettings()"
        color="secondary"
        class="ml-8"
        v-tooltip="$tooltip($t('calcRepAdmin.tooltip.save'))"
      >mdi-content-save-cog</v-icon>
    </v-card-title>

    <v-card-text class="pt-4">
      <v-row no-gutters>
        <v-col cols="12">
          <!-- hier wird die Tabelle angelegt -->
          <v-data-table
            v-model="selected"
            :headers="listHeaders"
            :items="reportList"
            :items-per-page="15"
            :footer-props="footerProps"
            item-key="id"
            fixed-header
            :sort-by="listSortKey"
            :loading="$store.state.calcRepAdmin.reportsLoading"
          >
            <template
                v-for="header in listHeaders.filter((header) => header.hasOwnProperty('formatter'))"
                v-slot:[`item.${header.value}`]="{ value }"
              >
              {{ header.formatter(value) }}
            </template>
            <template v-slot:[`body.prepend`]="{}">
                <tr height=75>
                  <td
                    v-for="(h, index) in listHeaders"
                    :key="index"
                  >
                  <SearchSelect
                    v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SelectBox'"
                    :value="filter[h.value].value"
                    @filter="applyFilters($event, h.value)"
                    :items="clientNameList"
                    :item-text="filter[h.value].itemText"
                    :item-value="filter[h.value].itemValue"
                  />
                    <SearchDatePicker
                      v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchDatePicker'"
                      :label="$t('base.from')"
                      :value="filter[h.value].validFrom"
                      @filter="applyFilters($event, 'date','validFrom','validTo')"
                    />
                    <SearchDatePicker
                      v-if="filter.hasOwnProperty(h.value) && filter[h.value].type === 'SearchDatePicker'"
                      :label="$t('base.to')"
                      :value="filter[h.value].validTo"
                      @filter="applyFilters($event, 'date','validTo','validFrom')"
                    />
                  </td>
                </tr>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
// import { isProcessStateOK } from '@/base/js/ProcessDataHelper';
// import { simpleFilter } from '@/base/js/ObjectHelper';
import { complexFilter } from '@/base/js/ObjectHelper';
import commonMixin from '@/common/js/commonMixin.js';
// import SearchField from '@/common/SearchField';
import SearchSelect from '@/common/SearchSelect';
import SearchDatePicker from '@/common/SearchDatePicker';
import { saveUserSettings } from '@/base/js/baseHelper';

// import SearchField from '@/base/components/SearchField';

export default {
  name: 'calcRepCountTable',
  components: {
    SearchSelect,
    SearchDatePicker
  },
  mixins: [ commonMixin ],
  data () {
    return {

      filter: {
        clientKey: { condition: 'startsWith', value: null },
        date: { condition: 'date.between', value: null, validFrom: null, validTo: null, type: 'SearchDatePicker' },
        Single: { condition: 'startsWith', value: null },
        clientName: { condition: 'startsWith', value: null, type: 'SelectBox' }
      },
      listSortKey: 'Datum',
      selected: [],
      footerProps: {
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 15, 30, 50, -1]
      },

      availableColumns: [
        // base.contact.* siehe Datei base/resources\texts/base_*.json
        // oder calcRepAdmin_*.json
        { text: this.$t('calcRepAdmin.countTable.clientName'), active: true, value: 'clientName' },
        { text: this.$t('calcRepAdmin.countTable.date'), active: true, value: 'date' },
        { text: this.$t('calcRepAdmin.countTable.singleCalc'), active: true, value: 'Single' }
      ]

    };
  },
  computed: {
    clientNameList () {
      let clNameList = [];
      for (let report of this.$store.state.calcRepAdmin.currentReportList) {
        const found = clNameList.find(element => element.clientName === report.clientName);
        if (!found) {
          clNameList.push(report.clientName);
        }
      }
      return clNameList;
    },
    reportList () {
      // $store siehe plugins/store.js
      return complexFilter(this.$store.state.calcRepAdmin.currentReportList, this.$store.state.calcRepAdmin.userSettings.calcRepCountTable.filter);
    },

    listHeaders () {
      let h = [];
      for (let mc of this.availableColumns) {
        if (mc.active === true) {
          h.push(mc);
        }
      }
      // h.push({ text: 'menu', value: 'menu', sortable: false });
      return h;
    }

  },
  mounted () {
    this.$emit('setPageMode', 'table');
  },
  methods: {
    async saveUserSettings () {
      // this.notImplemented('saveUserSettings');
      await saveUserSettings('condAdmin');
    },
    clearFilters () {
      this.filter = {
        date: '',
        singleCalc: '',
        bulkCalc: ''
      };
    },
    applyFilters (value, filtername, subFiltername = null, subFiltername2 = null) {
      /*
      if (subFiltername !== null) {
        this.filter[filtername][subFiltername] = value;
        if ((value === null || value === undefined) && subFiltername2 !== null) {
          value = this.filter[filtername][subFiltername2];
          this.filter[filtername][subFiltername] = null;
        }
      }
      this.filter[filtername].value = value;
      return complexFilter(this.$store.state.calcRepAdmin.currentReportList, this.filter); */

      let filter = this.$store.state.calcRepAdmin.userSettings.calcRepCountTable.filter;
      if (subFiltername !== null) {
        filter[filtername][subFiltername] = value;
        if ((value === null || value === undefined) && subFiltername2 !== null) {
          value = filter[filtername][subFiltername2];
          filter[filtername][subFiltername] = null;
        }
      }
      this.$store.state.calcRepAdmin.userSettings.calcRepCountTable.filter = filter;
      return complexFilter(this.$store.state.calcRepAdmin.currentReportList, filter);
    }
  }
};
</script>

<style scoped>
</style>
