<template>
  <v-container class="page">
    <Headline
      :title="headlineText"
      icon="mdi-label-percent-outline"
    />
    <router-view
      @setPageMode="setPageMode"
      @cancel="headlineText = $t('calcRepAdmin.calcRep.headline')"
    ></router-view>
  </v-container>
</template>

<script lang="js">
import Headline from '@/common/Headline.vue';

export default {
  name: 'calcRepCount',
  components: {
    Headline
  },
  data () {
    return {
      pageMode: 'table', // values: table, edit
      headlineText: this.$t('calcRepAdmin.calcRep.headline')
    };
  },
  methods: {
    setPageMode (mode, headlineParam = '') {
      this.pageMode = mode;
    }
  }
};
</script>

<style scoped lang="scss"></style>
