<template>
  <v-row class="py-1 justify-center">
    <div class="text-body-2" v-if="label != null">{{ label }}</div>
    <v-menu
      v-model="showPicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          class="px-0"
          small
        >
          <v-icon
            v-if="pickedValue === resetValue"
            color="grey"
            class="mr-2"
          >
            mdi-calendar
          </v-icon>
          <span
            class="text-body-2"
            v-if="pickedValue !== resetValue"
          >{{ displayedValue }}</span>
        </v-btn>
      </template>
      <v-date-picker
        :value="value === $t('base.NULL') ? null : value"
        @change="changed"
      >
        <v-btn
          @click="changed($t('base.NULL'))"
          color="secondary"
        >
          {{ $t('base.without') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="reset"
          color="secondary"
        >
          <v-icon class="mr-2">mdi-close-circle</v-icon>
          {{ $t(resetText) }}
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-btn
      v-if="pickedValue !== resetValue"
      icon
      @click="reset"
      small
      class="px-0"
    >
      <v-icon> $clear </v-icon>
    </v-btn>
  </v-row>
</template>

<script lang="js">

export default {
  name: 'DatePicker',
  props: {
    value: { type: String, default: null },
    nullText: { type: String, default: '' },
    resetText: { type: String, default: 'base.delete' },
    resetValue: { type: String, default: null },
    labelClass: { type: String, default: 'secondary--text' },
    label: { type: String, default: null }
  },
  data () {
    return {
      showPicker: false,
      pickedValue: null
    };
  },
  computed: {
    displayedValue () {
      if (this.pickedValue === this.$t('base.NULL')) return this.$t('base.NULL');
      if (this.pickedValue != null) return this.$filters.formatDate(this.pickedValue);
      if (this.value == null) return null;
      else return this.$filters.formatDate(this.value);
    }
  },
  methods: {
    reset () {
      this.pickedValue = null;
      this.$emit('filter', this.resetValue);
      this.showPicker = false;
    },
    changed (value) {
      this.pickedValue = value;
      this.$emit('filter', value);
      this.showPicker = false;
    }
  }
};
</script>

<style scoped>
.v-label {
  padding-top: 4px;
  padding-left: 2px;
  font-size: 0.875rem;
}
.v-label:not(.v-label--is-disabled) {
  color: var(--v-secondary-base);
}
.v-btn.v-size--small {
  height: 1rem !important;
}
</style>
