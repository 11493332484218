<template>
  <v-select
    v-model="filter"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :placeholder="$t(label)"
    @change="apply()"
    @click:clear="reset()"
    outlined
    clearable
    dense
    hide-details
  ></v-select>
</template>

<script lang="js">
export default {
  name: 'SearchSelect',
  props: {
    value: { type: [String, Number, Boolean], default: '' },
    label: { type: String, default: 'base.search' },
    items: { type: Array, required: true },
    itemText: { type: String, default: 'text' },
    itemValue: { type: String, default: 'value' }
  },
  data () {
    return {
      filter: this.value
    };
  },
  watch: {
    value () {
      this.filter = this.value;
    }
  },
  methods: {
    apply () {
      this.$emit('filter', this.filter);
    },
    reset () {
      this.$emit('filter', null);
    }
  }
};
</script>

<style scoped>
</style>
